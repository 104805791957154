import * as PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from 'styled-components';
import Button from '../atoms/Button';
import StyledList from "./StyledList";
import {withTranslation} from "react-i18next";

const ExpanderEl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
`;

const Expander = ({buttonLabel, buttonColor, list, t}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button fullWidth={true} onClick={() => setIsOpen(!isOpen)} bgColor={buttonColor}>
        {buttonLabel}
      </Button>
      <ExpanderEl>
        {isOpen && (
          <div align="center">
            <StyledList>
              {list.map(el => (<li key={el.label}><a href={el.download}>{t(el.label)}</a></li>))}
            </StyledList>
          </div>
        )}
      </ExpanderEl>
    </>
  );
};

Expander.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
};

export default withTranslation()(Expander);
