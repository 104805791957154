import Observer from '@researchgate/react-intersection-observer';
import { navigate } from 'gatsby';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import GlobalStyle from '../assets/styles/GlobalStyle';
import { COLORS } from '../assets/styles/variables';
import Button from '../atoms/Button';
import Spacer from '../atoms/Spacer';
import Text from '../atoms/Text';
import {
  LIVESTOCK_ADDONS_CARDS,
  LIVESTOCK_BOOKLETS,
  LIVESTOCK_FEATURES_CARDS,
  LIVESTOCK_PRODUCT_CARDS,
  LIVESTOCK_VIDEOS,
} from '../config/cardsContents';
import { ENTITIES, NAV_ITEMS, SECTIONS_IDS, URLS } from '../config/constants';
import Layout, { MyContext } from '../layout/LayoutContainer';
import '../locales/i18n';
import ForwardLink from '../molecules/ForwardLink';
import StyledList from '../molecules/StyledList';
import AdvantageCard from '../organisms/AdvantageCard/AdvantageCard';
import BecomePartnerSection from '../organisms/BecomePartnerSection/BecomePartnerSection';
import CTABanner from '../organisms/CTABanner/CTABanner';
import CTABannerContentWithBg from '../organisms/CTABanner/CTABannerContentWithBg';
import DescriptionText from '../organisms/CTABanner/CTABannerDescriptionText';
import CTABannerMainText from '../organisms/CTABanner/CTABannerMainText';
import ExploreCard from '../organisms/ExploreCard/ExploreCard';
import Footer, { MobileFooter } from '../organisms/Footer/Footer';
import Header from '../organisms/Header';
import ProductCard from '../organisms/ProductCard/ProductCard';
import SectionHeader from '../organisms/SectionHeader/SectionHeader';
import SectionHeaderMainText from '../organisms/SectionHeader/SectionHeaderMainText';
import SectionHeaderSubText from '../organisms/SectionHeader/SectionHeaderSubText';
import SolutionCard from '../organisms/SolutionCard/SolutionCard';
import SolutionCardActions from '../organisms/SolutionCard/SolutionCardActions';
import SolutionCardImage from '../organisms/SolutionCard/SolutionCardImage';
import { mobile, phone } from '../utils/media';
import Expander from '../molecules/Expander';

const CTABannerMobile = styled(CTABanner)`
  ${phone(css`
    min-height: 400px;
  `)}

  ${mobile(css`
    min-height: 350px;
  `)}
`;

const ProductCardWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 1440px;
  padding: 0 3%;
  margin: 40px auto;
  ${mobile(css`
    grid-template-columns: 1fr;
    gap: 20px;
  `)}
`;

const FeaturesCardsWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 1440px;
  padding: 0 3%;
  margin: 40px auto;
  ${mobile(css`
    gap: 20px;
  `)}
  ${phone(css`
    grid-template-columns: 1fr;
  `)}
`;

const SolutionsCardWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 1440px;
  padding: 0 3%;
  margin: 40px auto;
  ${phone(css`
    grid-template-columns: 1fr;
  `)}
  ${mobile(css`
    gap: 20px;
  `)}
`;

class LivestockPage extends React.Component {
  state = {
    [SECTIONS_IDS.dealer]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.agronomist]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.farmer]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.dairyFarmer]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.opinions]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.partners]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.products]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.productFeatures]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.addons]: { visited: false, visibility: 'hidden' },
    [SECTIONS_IDS.explore]: { visited: false, visibility: 'hidden' },
  };

  handleSectionChange = (event) => {
    const targetId = event && event.target.id;
    this.setState({
      [targetId]: {
        visibility: event.isIntersecting ? 'visible' : 'hidden',
        visited: window.scrollY > 0,
      },
    });
  };

  getSectionClassName = (sectionId) => {
    return `fading-box ${this.state[sectionId].visibility} ${this.state[sectionId].visited && 'visited'}`;
  };

  onLogin = () => window.open(URLS.login, '_BLANK');
  onPlatformSignup = () => window.open(URLS.signup, '_BLANK');
  onSignup = (persona) => {
    if (persona === ENTITIES.dealer) {
      navigate(NAV_ITEMS.contactUs.linkTo, {
        state: { persona },
      });
    } else if (persona === ENTITIES.agronomist || persona === ENTITIES.farmer) {
      window.open(URLS.signup + '?areaOfInterest=farmer', '_BLANK');
    } else if (persona === ENTITIES.dairyFarmer) {
      window.open(URLS.signup + '?areaOfInterest=breeder', '_BLANK');
    } else {
      window.open(URLS.signup, '_BLANK');
    }
  };

  render() {
    const { t } = this.props;

    const HeaderCon = (props) => {
      return (
        <MyContext.Consumer>{(value) => <Header loginCb={this.onLogin} {...value} {...props} />}</MyContext.Consumer>
      );
    };
    return (
      <Layout loginCb={this.onLogin} {...this.props}>
        <HeaderCon />

        <CTABannerMobile height={450} bgImage={'livestock/livestock-hero.png'} bgPosition={'center'} overlay={false}>
          <div>
            <CTABannerContentWithBg>
              <CTABannerMainText block size="32px" transform="none" weight="normal">
                {t('app:livestock:cta:title')}
              </CTABannerMainText>
              <Spacer height="15px" />
              <DescriptionText size="22px" align="left" transform="none" weight="normal">
                {t('app:livestock:cta:description')}
              </DescriptionText>
            </CTABannerContentWithBg>
          </div>
        </CTABannerMobile>

        <section>
          <SectionHeader>
            <SectionHeaderSubText transform="none" lineHeight="1.2" color={COLORS.greyText}>
              {t('app:livestock:content1')}
            </SectionHeaderSubText>
            <SectionHeaderSubText transform="none" lineHeight="1.2" color={COLORS.greyText}>
              <Trans i18nKey={'app:livestock:content2'}>
                <Text weight={'bold'} />
              </Trans>
            </SectionHeaderSubText>
          </SectionHeader>
        </section>

        <Observer rootMargin="0% 0% -5%" onChange={this.handleSectionChange}>
          <ProductCardWrapper id="products" className={this.getSectionClassName('products')}>
            {LIVESTOCK_PRODUCT_CARDS &&
              LIVESTOCK_PRODUCT_CARDS.map((product, i) => (
                <ProductCard
                  key={i}
                  title={t(`app:livestock:products:${product.id}_title`)}
                  productName={t(`app:livestock:products:${product.id}`)}
                  description={
                    <Trans i18nKey={`app:livestock:products:${product.id}_description`}>
                      <li></li>
                    </Trans>
                  }
                  image={product.image}
                  actions={
                    <SolutionCardActions>
                      {product.actions.map((action) =>
                        !action.disabled ? (
                          <ForwardLink target={'_BLANK'} key={action.label} linkTo={action.linkTo}>
                            <Button bgColor={COLORS.primary} fullWidth={true}>
                              {t(action.label)}
                            </Button>
                          </ForwardLink>
                        ) : (
                          <Button bgColor={COLORS.primary} fullWidth={true} disabled>
                            {t(action.label)}
                          </Button>
                        ),
                      )}
                      {product.googlePlay && (
                        <ForwardLink target={'_BLANK'} linkTo={product.googlePlay}>
                          <Button role="google-play" fullWidth={true} />
                        </ForwardLink>
                      )}
                      {product.appStore && (
                        <ForwardLink target={'_BLANK'} linkTo={product.appStore}>
                          <Button linkTo={product.appStore} role="app-store" fullWidth={true} />
                        </ForwardLink>
                      )}
                    </SolutionCardActions>
                  }
                />
              ))}
          </ProductCardWrapper>
        </Observer>

        <SectionHeader>
          <SectionHeaderMainText lineHeight="1.2" color={COLORS.greyText} size="42px" transform="none">
            {t(`app:livestock:features_title`)}
          </SectionHeaderMainText>
        </SectionHeader>

        <Observer rootMargin="0% 0% -5%" onChange={this.handleSectionChange}>
          <ProductCardWrapper id="productFeatures" className={this.getSectionClassName('productFeatures')}>
            {LIVESTOCK_PRODUCT_CARDS &&
              LIVESTOCK_PRODUCT_CARDS.map((product, i) => {
                const features = t(`app:livestock:products:${product.id}_features`, { defaultValue: null });
                if (features === null) return null;

                return (
                  <ProductCard
                    key={i}
                    title={t(`app:livestock:products:${product.id}`)}
                    description={
                      <Trans i18nKey={`app:livestock:products:${product.id}_features`}>
                        <StyledList>
                          <li></li>
                        </StyledList>
                      </Trans>
                    }
                    note={t(product.note)}
                    actions={
                      <SolutionCardActions>
                        {product.actions.map((action) => {
                          if (action.assets) {
                            return (
                              <Expander
                                key={action.label}
                                buttonLabel={t(action.label)}
                                buttonColor={COLORS.primary}
                                list={action.assets}
                              />
                            );
                          }
                          return !action.disabled ? (
                            <ForwardLink key={action.label} target={'_BLANK'} linkTo={action.linkTo}>
                              <Button bgColor={COLORS.primary} fullWidth={true}>
                                {t(action.label)}
                              </Button>
                            </ForwardLink>
                          ) : (
                            <Button bgColor={COLORS.primary} fullWidth={true} disabled key={action.label}>
                              {t(action.label)}
                            </Button>
                          );
                        })}
                        {product.googlePlay && (
                          <ForwardLink target={'_BLANK'} linkTo={URLS.feedAppGooglePlayUrl}>
                            <Button role="google-play" fullWidth={true} />
                          </ForwardLink>
                        )}
                        {product.appStore && (
                          <ForwardLink target={'_BLANK'} linkTo={URLS.feedAppAppleStoreUrl}>
                            <Button role="app-store" fullWidth={true} />
                          </ForwardLink>
                        )}
                      </SolutionCardActions>
                    }
                  />
                );
              })}
          </ProductCardWrapper>
        </Observer>

        <SectionHeader>
          <SectionHeaderMainText lineHeight="1.2" color={COLORS.greyText} size="42px" transform="none">
            {t(`app:livestock:addons_title`)}
          </SectionHeaderMainText>
        </SectionHeader>

        <Observer rootMargin="0% 0% -5%" onChange={this.handleSectionChange}>
          <SolutionsCardWrapper id="addons" className={this.getSectionClassName('addons')}>
            {LIVESTOCK_ADDONS_CARDS &&
              LIVESTOCK_ADDONS_CARDS.map((item, i) => (
                <SolutionCard key={i}>
                  <SectionHeaderMainText
                    transform="none"
                    align="center"
                    weight="bold"
                    size="32px"
                    lineHeight="1.2"
                    color={COLORS.greyText}
                  >
                    {t(`app:livestock:addons:${item.id}`)}
                  </SectionHeaderMainText>
                  <SolutionCardImage image={`livestock/${item.image}`} />
                  <SectionHeaderSubText block size="20px" color={COLORS.greyText} lineHeight="1.4">
                    {t(`app:livestock:addons:${item.id}_description`)}
                  </SectionHeaderSubText>
                  <SolutionCardActions>
                    {item.actions.map((action) => {
                      if (action.assets) {
                        return (
                          <Expander
                            key={action.label}
                            buttonLabel={t(action.label)}
                            buttonColor={COLORS.primary}
                            list={action.assets}
                          />
                        );
                      }
                      return !action.disabled ? (
                        <Button bgColor={COLORS.primary} fullWidth>
                          {t(action.label)}
                        </Button>
                      ) : (
                        <Button bgColor={COLORS.primary} fullWidth disabled>
                          {t(action.label)}
                        </Button>
                      );
                    })}
                  </SolutionCardActions>
                </SolutionCard>
              ))}
          </SolutionsCardWrapper>
        </Observer>

        <SectionHeader>
          <SectionHeaderMainText lineHeight="1.2" color={COLORS.greyText} size="42px" transform="none">
            {t(`app:livestock:optimization_title`)}
          </SectionHeaderMainText>
        </SectionHeader>

        <FeaturesCardsWrapper>
          {LIVESTOCK_FEATURES_CARDS.map((item) => {
            return (
              <AdvantageCard
                key={item.id}
                inRow={true}
                title={t(`app:livestock:features:${item.id}`)}
                text={t(`app:livestock:features:${item.id}_text`)}
                icon={item.image}
                bigIcon={true}
              />
            );
          })}
        </FeaturesCardsWrapper>

        <SectionHeader>
          <SectionHeaderMainText lineHeight="1.2" color={COLORS.greyText} size="42px" transform="none">
            {t(`app:livestock:explore_title`)}
          </SectionHeaderMainText>
        </SectionHeader>

        <Observer rootMargin="0% 0% -5%" onChange={this.handleSectionChange}>
          <SolutionsCardWrapper id="explore" className={this.getSectionClassName('explore')}>
            <ExploreCard title={t('app:booklets_title')} items={LIVESTOCK_BOOKLETS} t={t} />
            <ExploreCard title={t('app:videos_title')} items={LIVESTOCK_VIDEOS} t={t} />
          </SolutionsCardWrapper>
        </Observer>

        <BecomePartnerSection />

        <Footer />
        <MobileFooter />

        <GlobalStyle />
      </Layout>
    );
  }
}

LivestockPage.propTypes = {};

export default withTranslation()(LivestockPage);
